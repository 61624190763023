import { ListCheck, LogOut, Newspaper, X } from 'lucide-react';
import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useSidebar } from '../../context/SidebarContext';

const StyledSidebar = styled.nav`
  background-color: ${props => props.theme.colors.background};
  border-right: 1px solid ${props => props.theme.colors.backgroundFade};
  height: 100%;
  padding: 1.0rem;

  & > header {
    height: 3.0rem;
    padding: 1.0rem;
    margin-bottom: 1.0rem;
    color: ${props => props.theme.colors.main};
    font-size: 2.0rem;
    font-weight: 700;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  ul {
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 2.0rem;
    padding-left: 1.0rem;
  }
  
  li {
    list-style: none;
  }

  a {
    text-decoration: none;
    color: ${props => props.theme.colors.main};
    opacity: 0.5;
    font-size: 1.6rem;
    font-weight: 700;
    display: flex;
    align-items: center;
    gap: 1.0rem;
  }

  a.active {
    opacity: 1;
  }

  .close-icon {
    cursor: pointer;
  }
`

const Sidebar: React.FC = () => {

  const logout = () => {
    localStorage.removeItem('token');
    window.location.href = "/login"
  }

  return (
      <StyledSidebar>
        <header>
          <span>Plataforma</span>
        </header>
        <ul>
          <li>
            <NavLink to="/home" end className={({ isActive }) => (isActive ? 'active' : '')}>
              <Newspaper size="1.6rem" />
              <span>Notícias</span>
            </NavLink>
          </li>
          <li>
            <a 
              href="https://form.respondi.app/kcEB5u3R" 
              target="_blank" 
              rel="noopener noreferrer"
            >
              <ListCheck size="1.6rem" />
              <span>Feedback</span>
            </a>
          </li>
          <li>
            <NavLink onClick={() => logout()} to="/" className={({ isActive }) => (isActive ? 'active' : '')}>
              <LogOut size="1.6rem" />
              <span>Sair</span>
            </NavLink>
          </li>
        </ul>
    </StyledSidebar>
  );
}

export default Sidebar;