import styled from "styled-components";

interface DefaultLayoutProps {
    children: React.ReactNode;
}

const StyledLayout = styled.div`
  height: 100vh;
  width: 100vw;
  display: grid;
  place-items: center;
  background-color: ${props => props.theme.colors.background};

  & > main {
    overflow: auto;
  }
  
`

const LoginLayout: React.FC<DefaultLayoutProps> = ({ children }) => {

  return (
      <StyledLayout>
        <main>
          { children }
        </main>

      </StyledLayout>
  )
}

export default LoginLayout

