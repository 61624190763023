import { ReactNode } from "react";
import styled from "styled-components";

interface Props {
  isOpen: boolean;
  children: ReactNode;
}

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: ${props => props.theme.colors.backgroundFade}90;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding-right: 1.6rem;
`

const ModalContent = styled.div`
  width: 100vw;
  height: 90vh;
  display: flex;
  margin: 1.6rem;
  flex-direction: column;
  align-items: stretch;
  border-radius: 0.8rem;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
`

const Modal: React.FC<Props> = ({ isOpen, children }) => {

  if (!isOpen) return <></>
  return (
    <ModalBackground>
      <ModalContent onClick={(e) => e.stopPropagation()}>
      { children }
      </ModalContent>
    </ModalBackground>
  );

}

export default Modal;