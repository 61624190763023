import LoginLayout from "../../layouts/LoginLayout"
import ResetPassword from "../../pages/ResetPassword"


const ResetPasswordPage: React.FC = () => {
    return (
        <LoginLayout>
          <ResetPassword />
        </LoginLayout>
    )
}

export default ResetPasswordPage