const theme_light = {
  colors: {
    background: '#eae8e5',
    backgroundFade: '#d9d7d4',
    primary: '#000',
},
  font: {
      color: 'black',
  }
}
  
const theme_dark = {
    colors: {
        background: '#111827',
        backgroundFade: '#374151',
        backgroundFade2: '#1F2937',
        backgroundFade3: '#4b5563',
        primary: '#fff',
        highlight: '#EAB308',
        faded: '#7b8998',
        main: 'white',
        light: '#c1bdae',
    }
}

export {
    theme_light,
    theme_dark
}