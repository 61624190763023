import styled from "styled-components";
import Sidebar from "../../components/Sidebar";
import { useSidebar } from "../../context/SidebarContext";

interface DefaultLayoutProps {
    children: React.ReactNode;
}

const StyledLayout = styled.div`
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-areas: "sidebar content";
  grid-template-columns: auto 1fr;

  & > aside {
    grid-area: sidebar;
    width: 25.0rem;
  }

  & > main {
    grid-area: content;
    overflow: auto;
  }
  
`

const DefaultLayout: React.FC<DefaultLayoutProps> = ({ children }) => {

  const { isSidebarOpen } = useSidebar()

  return (
      <StyledLayout>
        {
          isSidebarOpen &&
          <aside>
            <Sidebar />
          </aside>
        }

        <main>
          { children }
        </main>


      </StyledLayout>
  )
}

export default DefaultLayout

