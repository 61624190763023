import { useEffect, useState } from "react";

export function useMediaQuery(query: string): boolean {
  const [matches, setMatches] = useState<boolean>(false);

  useEffect(() => {
    const media = window.matchMedia(query);

    const listener = (event: MediaQueryListEvent) => setMatches(event.matches);

    // Initial check
    setMatches(media.matches);

    // Add the listener using addEventListener
    media.addEventListener('change', listener);

    // Cleanup the listener on unmount
    return () => media.removeEventListener('change', listener);
  }, [query]);

  return matches;
}