import React from 'react';
import styled from 'styled-components';
import { useSidebar } from '../../context/SidebarContext';
import { Menu } from 'lucide-react';

const StyledButton = styled.button`
  background: none;
  border: none;
  color: ${props => props.theme.colors.main};
  font-size: 2.4rem;
  margin-right: 2.0rem;
  cursor: pointer;
  display: flex;
  align-items: center;
`

const MenuBurger: React.FC = () => {

  const { toggleSidebar } = useSidebar()

  return (
    <StyledButton onClick={toggleSidebar}>
      <Menu />
    </StyledButton>
  );
}

export default MenuBurger;