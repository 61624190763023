import { useEffect, useRef, useState } from "react"
import { debounce } from 'lodash'
import ListItem from "./components/ListItem"
import Article from "../../entities/Article"
import { getFirstPage, getLatest, getNextPage } from "../../services/ArticleService"
import MenuBurger from "../../components/MenuBurger"
import TextPanel from "../../components/TextPanel"
import { StyledContent, StyledHeader, StyledPage } from "./styles"
import Modal from "../../components/Modal"
import { useMediaQuery } from "../../hooks/useMediaQuery"
import Spinner from "../../components/Spinner"

const Dashboard: React.FC = () => {

  const [articlePanel, setArticlePanel] = useState<Article | null>(null)
  const [articles, setArticles] = useState<Article[]>([]); 
  const [newArticlesIds, setNewArticlesIds] = useState<string[]>([]);
  
  const [latestTimestamp, setLatestTimestamp] = useState<number>(Date.now());
  const latestTimestampRef = useRef(latestTimestamp);

  const [oldestTimestamp, setOldestTimestamp] = useState<number>(Date.now());
  const oldestTimestampRef = useRef(oldestTimestamp);

  const divRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    if (!divRef.current) return;

    const { scrollTop, scrollHeight, clientHeight } = divRef.current;
    if (scrollTop + clientHeight >= scrollHeight - 10) {
      fetchNextPage();
    }
  }

  const debouncedHandleScroll = debounce(handleScroll, 200);

  useEffect(() => {
    const divElement = divRef.current;
    if (divElement) {
      divElement.addEventListener('scroll', debouncedHandleScroll)
    }

    fetchNewPage();

    const intervalId = setInterval(() => {
      fetchLatest()
    }, 60000); 

    return () => {
      clearInterval(intervalId);
      if (divElement) {
        divElement.removeEventListener('scroll', debouncedHandleScroll);
      }
    };
  }, []);

  useEffect(() => {
    latestTimestampRef.current = latestTimestamp;
  }, [latestTimestamp]);

  useEffect(() => {
    oldestTimestampRef.current = oldestTimestamp;
  }, [oldestTimestamp]);

  const refreshLatestTimestamp = (data: Article[]) => {
    if (data.length) {
      setLatestTimestamp(data[0].timestamp)
    }
  }

  const refreshOldestTimestamp = (data: Article[]) => {
    const length = data.length;
    if (length) {
      setOldestTimestamp(data[length-1].timestamp)
    }
  }

  const fetchNewPage = async () => {
    try {
      const data = await getFirstPage();
      if (data.length) {
        refreshLatestTimestamp(data);
        refreshOldestTimestamp(data);
        setArticles(data)
      }
    } catch (error) {
      console.log(error);
    }
  }

  const fetchLatest = async () => {
    try {
      const data = await getLatest(latestTimestampRef.current);
      if (data.length) {
        setArticles(articles => [...data, ...articles]);
        setNewArticlesIds(data.map(a => a.id));
        refreshLatestTimestamp(data);

        setTimeout(() => {
          setNewArticlesIds([]);
        }, 3000);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const fetchNextPage = async () => {
    try {
      const data = await getNextPage(oldestTimestampRef.current);
      if (data.length) {
        setArticles(articles => [...articles, ...data]);
        refreshOldestTimestamp(data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const onClickItem = (item: Article) => {
    setArticlePanel(item)
  }

  const onClosePanel = () => {
    setArticlePanel(null)
  }

  const isMobile = useMediaQuery('(max-width: 80.0rem)');

  return (
    <StyledPage>
      <StyledHeader>
        <MenuBurger />
        <h1>Notícias</h1>
      </StyledHeader>
      <StyledContent $showPanel={!!articlePanel && !isMobile}>
        <div className="articles-list" ref={divRef}>
          {
            (articles || []).length 
              ? articles.map((a, i) => (
                  <ListItem 
                    key={a.id} 
                    data={a} 
                    onClick={onClickItem}
                    selected={a === articlePanel}
                    highlight={newArticlesIds.includes(a.id)}
                  />
                ))
              : <Spinner $light />
          }
        </div>
        { articlePanel && 
         ( 
          isMobile
          ? <Modal isOpen>
              <TextPanel data={articlePanel} onClose={onClosePanel} />
            </Modal>
          : <div className="article-panel">
              <TextPanel data={articlePanel} onClose={onClosePanel} />
            </div>
         )
        }
        
      </StyledContent>
    </StyledPage>
    
  )
}

export default Dashboard