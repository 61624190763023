import axios from 'axios'
import User from '../entities/User'
import Token from '../entities/Token'

const login = (user: User): Promise<Token> => {
  return new Promise((resolve, reject) => {
    try {
      axios.post(`${process.env.REACT_APP_API_URL}/login`, 
        { email: user.email, password: user.password})
        .then((response: any) => {
          if (response) {
            resolve({ value: response.data.token });
          } else {
            reject(response)
          }
      })
      .catch(reject)
    } catch (e) {
      reject(e)
    }
  })
}

const registerNewUser = (user: User): Promise<number> => {
  return new Promise((resolve, reject) => {
    try {
      axios.post(`${process.env.REACT_APP_API_URL}/users`, 
        { email: user.email, password: user.password})
        .then((response: any) => {
          if (response) {
            resolve(0);
          } else {
            reject(response)
          }
      })
      .catch(reject)
    } catch (e) {
      reject(e)
    }
  })
}

const forgetPassword = (email: string): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    try {
      axios.post(`${process.env.REACT_APP_API_URL}/forgot-password`, 
        { email })
        .then((response: any) => {
          if (response) {
            resolve(true);
          } else {
            reject(response)
          }
      })
      .catch(reject)
    } catch (e) {
      reject(e)
    }
  })
}

const updatePassword = (token: string, newPassword: string): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    try {
      axios.post(`${process.env.REACT_APP_API_URL}/reset-password/${token}`, 
        { newPassword })
        .then((response: any) => {
          if (response) {
            resolve(true);
          } else {
            reject(response)
          }
      })
      .catch(reject)
    } catch (e) {
      reject(e)
    }
  })
}


export {
  login,
  registerNewUser,
  forgetPassword,
  updatePassword,
}
