import React, { useState, FormEvent } from 'react';
import styled from 'styled-components';
import PrimaryButton from '../../components/PrimaryButton';
import Input from '../../components/Input';
import { updatePassword } from '../../services/LoginService';
import { useLocation, useNavigate, useParams } from 'react-router-dom';


const StyledContainer = styled.div`
  width: 35rem;
  margin: 0 auto;
  padding: 2rem;
  border: 1px solid ${props => props.theme.colors.primary};
  border-radius: 0.5rem;
  background-color: ${props => props.theme.colors.background};
  color: ${props => props.theme.colors.primary};
  font-size: 1.6rem;

  h2 {
    text-align: center;
  }

  label {
    display: block;
    margin-bottom: 0.1rem;
  }

  input {
    margin-bottom: 1.5rem;
  }

  .error {
    color: red;
    font-size: 0.9em;
    text-align: center;
  }

  button {
    width: 100%;
  }
`

const ResetPassword: React.FC = () => {
  const [password, setPassword] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');

  const navigate = useNavigate();

  const handleSubmit = async () => {
    if (token && password) {
      setLoading(true);
      try {
        await updatePassword(token, password);
        navigate('/');
      } catch (err) {
        setLoading(false);
        alert('Erro ao gerar nova senha.')
      }
    }
  };

  return (
    <StyledContainer>
      <h2>Login</h2>
      <div>
        <div>
          <label htmlFor="password">Nova senha:</label>
          <Input password id="password" value={password} onChange={setPassword} />
        </div>
        <PrimaryButton loading={loading} text="Enviar nova senha" onClick={handleSubmit}></PrimaryButton>
      </div>
    </StyledContainer>
  );
};

export default ResetPassword;
