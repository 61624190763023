function portalsMap(portal: string): string {
  switch (portal) {
    case "bbc": return "BBC";
    case "braziljournal": return "BRAZIL JOURNAL";
    case "cnn": return "CNN";
    case "estadao": return "ESTADÃO";
    case "exame": return "EXAME";
    case "folha": return "FOLHA";
    case "forbes": return "FORBES";
    case "globo": return "G1";
    case "infomoney": return "INFOMONEY";
    case "jovem-pan": return "JOVEM PAN";
    case "metropoles": return "METROPOLES";
    case "o-globo": return "OGLOBO";
    case "poder360": return "PODER360";
    case "bloomberg": return "BLOOMBERG LÍNEA";
    case "broadcast": return "BROADCAST";
    case "valoreconomico": return "VALOR ECONÔMICO";
    case "valorinveste": return "VALOR INVESTE";
    default: return ""
  }
}

export {
  portalsMap,
}
