import Dashboard from "../../pages/Dashboard"
import DefaultLayout from "../../layouts/DefaultLayout"


const DashboardPage: React.FC = () => {
    return (
        <DefaultLayout>
            <Dashboard/>
        </DefaultLayout>
    )
}

export default DashboardPage