import React, { useState, FormEvent } from 'react';
import styled from 'styled-components';
import PrimaryButton from '../../components/PrimaryButton';
import Input from '../../components/Input';
import { login } from '../../services/LoginService';
import { useNavigate } from 'react-router-dom';


const StyledLogin = styled.div`
  width: 90vw;
  max-width: 35rem;
  box-sizing: border-box;
  margin: 1.6rem;
  padding: 2rem;
  border: 1px solid ${props => props.theme.colors.primary};
  border-radius: 0.5rem;
  background-color: ${props => props.theme.colors.background};
  color: ${props => props.theme.colors.primary};
  font-size: 1.6rem;

  h2 {
    text-align: center;
  }

  button {
    width: 100%;
  }

  label {
    display: block;
    margin-bottom: 0.1rem;
  }

  .input-container {
    flex-grow: 1;
  }

  .error {
    color: red;
    font-size: 0.9em;
    text-align: center;
  }

  .field-input {
    margin-bottom: 1.5rem;
  }

  a {
    color: ${props => props.theme.colors.primary};
    display: block;
    margin-top: 1.0rem;
  }
`

const Login: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleSubmit = async () => {
    setError('');
    setLoading(true);

    try {
      const token = await login({ email: email, password });
      if (token) {
        localStorage.setItem('token', token.value); // Store the JWT
        navigate('/home')
      } else {
        console.log(token)
        setError(token || 'Login failed');
      }
    } catch (err) {
      setError('An error occurred. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledLogin>
      <h2>Login</h2>
      <div className="input-container">
        <div className="field-input">
          <label htmlFor="email">Usuário:</label>
          <Input id="email" value={email} onChange={setEmail} />
        </div>
        <div className="field-input">
          <label htmlFor="password">Senha:</label>
          <Input password id="password" value={password} onChange={setPassword} />
        </div>
        {error && <p className="error">{error}</p>}
        <PrimaryButton loading={loading} text="Login" onClick={handleSubmit}></PrimaryButton>
        <a href="/forgot">Esqueceu sua senha?</a>
      </div>
    </StyledLogin>
  );
};

export default Login;
