import axios from 'axios'
import Article from '../entities/Article'
import { isTokenExpired } from '../utils/JwtUtils';

const createAuthenticatedRequest = () => {
  const api = axios.create({});

  api.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    if (token && isTokenExpired(token)) {
      localStorage.removeItem('token');
      window.location.href = '/';
    } else if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  return api;
};

const middleware = (r: any) => ({
  id: r._id,
  portal: r.parser,
  title: r.title,
  subtitle: r.subtitle,
  timestamp: r.timestamp * 1000,
  content: r.text,
  url: r.url,  
})

const getFirstPage = (): Promise<Article[]> => {
  return new Promise((resolve, reject) => {
    try {
      const axiosInstance = createAuthenticatedRequest();
      axiosInstance.get(`${process.env.REACT_APP_API_URL}/articles/first-page`)
        .then((response: any) => {
          if (response) {
            const articles = response.data.map(middleware);
            resolve(articles)
          } else {
              reject(response)
          }
      })
      .catch(reject)
    } catch (e) {
      reject(e)
    }
  })
}

const getLatest = (timestamp: number): Promise<Article[]> => {
  return new Promise((resolve, reject) => {
    try {
      const axiosInstance = createAuthenticatedRequest();
      axiosInstance.get(`${process.env.REACT_APP_API_URL}/articles/latest?timestamp=${timestamp/1000}`)
        .then((response: any) => {
          if (response) {
            const articles = response.data.map(middleware);
            resolve(articles)
          } else {
              reject(response)
          }
      })
      .catch(reject)
    } catch (e) {
      reject(e)
    }
  })
}

const getNextPage = (timestamp: number): Promise<Article[]> => {
  return new Promise((resolve, reject) => {
    try {
      const axiosInstance = createAuthenticatedRequest();
      axiosInstance.get(`${process.env.REACT_APP_API_URL}/articles?limit=20&beforeTimestamp=${timestamp/1000}`)
        .then((response: any) => {
          if (response) {
            const articles = response.data.map(middleware);
            resolve(articles)
          } else {
              reject(response)
          }
      })
      .catch(reject)
    } catch (e) {
      reject(e)
    }
  })
}

export {
    getFirstPage,
    getLatest,
    getNextPage,
}
