import styled from "styled-components";

const StyledPage = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.colors.background};

  display: grid;
  grid-template-rows: 1fr 30fr;
`

const StyledHeader = styled.header`
  background-color: ${props => props.theme.colors.background};
  color: ${props => props.theme.colors.main};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 2.5rem 2.0rem;

  h1 {
    margin-right: auto;
    font-size: 2.0rem;
  }
`

const StyledContent = styled.div<{ $showPanel: boolean; }>`
  display: grid;
  grid-template-columns: ${({ $showPanel }) => ($showPanel ? '1fr 1fr' : '1fr')};
  background-color: ${props => props.theme.colors.background};
  height: 100%;
  gap: 1.5rem;
  padding: 0 1.5rem;

  .articles-list {
    box-sizing: border-box;
    max-height: 85vh;
    overflow: auto;
    background-color: ${props => props.theme.colors.backgroundFade2};
    border: solid 1px ${props => props.theme.colors.backgroundFade};
    border-radius: 0.8rem;
    
    padding: 0.9rem;

    display: flex;
    flex-direction: column;
    gap: 1.0rem;
  }

  .article-panel {
    max-height: 85vh;
  }
`

export {
  StyledPage,
  StyledHeader,
  StyledContent,
}