import LoginLayout from "../../layouts/LoginLayout"
import ForgotPassword from "../../pages/ForgotPassword"


const ForgotPasswordPage: React.FC = () => {
    return (
        <LoginLayout>
          <ForgotPassword />
        </LoginLayout>
    )
}

export default ForgotPasswordPage