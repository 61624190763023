import React from 'react'
import './App.css'
import { ThemeProvider } from 'styled-components'
import { theme_light, theme_dark } from './theme'
import AppRouter from './Router'
import { SidebarProvider } from './context/SidebarContext'

const App: React.FC = () => {

  return (
    <ThemeProvider theme={theme_dark}>
      <SidebarProvider>
        <AppRouter />
      </SidebarProvider>
    </ThemeProvider>
  )
};

export default App
