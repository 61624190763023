import { X } from "lucide-react";
import styled from "styled-components"
import { formatDateTimeOnly } from "../../utils/DateUtils";
import Article from "../../entities/Article";
import { portalsMap } from "../../utils/PortalsUtils";

interface Props {
    data: Article;
    onClose: () => void;
}

const StyledTextPanel = styled.div`
    box-sizing: border-box;
    overflow-y: auto;
    position: relative;
    align-self: start;
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 1.0rem;
    background-color: ${props => props.theme.colors.backgroundFade2};
    border: solid 1px ${props => props.theme.colors.backgroundFade};
    border-radius: 0.8rem;
    padding: 2.0rem;

    h2 {
      color: ${props => props.theme.colors.main};
      margin: 0 1.6rem 1.6rem 0;
      font-size: 2.0rem;
    }

    p {
      color: ${props => props.theme.colors.main};
      font-size: 1.6rem;
      white-space: pre-wrap;
      margin: 0;
    }

    span {
      color: ${props => props.theme.colors.highlight};
      font-size: 1.6rem;
    }

    a {
      color: ${props => props.theme.colors.highlight};
      font-size: 1.6rem;
      word-break: break-word;
    }

    & > svg {
      position: absolute;
      cursor: pointer;
      right: 1.6rem;
      color: white;
    }
`

const TextPanel: React.FC<Props> = ({ data, onClose }) => {
    return (
        <StyledTextPanel>
            <X onClick={onClose} />
            <h2>{data.title}</h2>
            <span>{formatDateTimeOnly(data.timestamp)} - {portalsMap(data.portal)}</span>
            {
              data.content.split('\n').map((line, index) => (
                <p key={index}>{line}</p>
              ))
            }
            <p>Fonte: <a href={data.url} target="_blank">{data.url}</a></p>
        </StyledTextPanel>
    ) 

}

export default TextPanel;

