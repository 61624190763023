import styled from "styled-components"
import Article from "../../../../entities/Article";
import { formatDateTimeOnly } from "../../../../utils/DateUtils";
import { portalsMap } from "../../../../utils/PortalsUtils";

interface Props {
    onClick: (item: Article) => void;
    data: Article;
    selected?: boolean;
    highlight?: boolean;
}

const StyledListItem = styled.div`  
    padding: 1.0rem;
    font-size: 1.6rem;
    background-color: ${props => props.theme.colors.backgroundFade};
    border-radius: 0.5rem;
    border-left: 4px solid ${props => props.theme.colors.backgroundFade};
    
    display: grid;
    gap: 1.0rem;
    grid-template-rows: repeat(1fr, 2);
    
    cursor: pointer;

    &:hover {
      background-color: ${props => props.theme.colors.backgroundFade3};
      border-left: 4px solid ${props => props.theme.colors.backgroundFade3};
    }

    &.selected {
      background-color: ${props => props.theme.colors.backgroundFade3};
      border-left: 4px solid ${props => props.theme.colors.highlight};
    }

    @keyframes highlight {
      from {
        background-color: ${props => props.theme.colors.highlight};
      }
      to {
        background-color: ${props => props.theme.colors.backgroundFade};
      }
    }

    &.highlight {
      animation: highlight 2s ease-out;
    }

    span {
      color: ${props => props.theme.colors.main}
    }

    .title {
      color: ${props => props.theme.colors.highlight}
    }
`

const ListItem: React.FC<Props> = ({ onClick, data, selected, highlight }) => {

    return (
        <StyledListItem className={`${selected ? 'selected' : ''} ${highlight ? 'highlight': ''}`} onClick={() => onClick(data)}>
          <span className="title">{formatDateTimeOnly(data.timestamp)} {portalsMap(data.portal)}</span>
          <span 
              onClick={() => {}}
          >
              {data.title}
          </span>
        </StyledListItem>
    ) 

}

export default ListItem;

