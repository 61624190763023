import styled, { keyframes } from 'styled-components';


const spin = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

interface Props {
  $light?: boolean;
}

const Spinner = styled.div<Props>`
  border: 4px solid ${({ $light }) => ($light ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)')};
  border-left-color: ${({ $light }) => ($light ? '#fff' : '#000')};
  border-radius: 50%;
  width: 1.6rem;
  height: 1.6rem;
  animation: ${spin} 1s linear infinite;
  margin: auto;
`

export default Spinner;