import React, { useState, FormEvent } from 'react';
import styled from 'styled-components';
import PrimaryButton from '../../components/PrimaryButton';
import Input from '../../components/Input';
import { forgetPassword, login, registerNewUser } from '../../services/LoginService';
import { useNavigate } from 'react-router-dom';


const StyledContainer = styled.div`
  width: 35rem;
  margin: 0 auto;
  padding: 2rem;
  border: 1px solid ${props => props.theme.colors.primary};
  border-radius: 0.5rem;
  background-color: ${props => props.theme.colors.background};
  color: ${props => props.theme.colors.primary};
  font-size: 1.6rem;

  h2 {
    text-align: center;
  }

  input {
    margin-bottom: 1.5rem;
  }

  label {
    display: block;
    margin-bottom: 0.1rem;
  }

  .error {
    color: red;
    font-size: 0.9em;
    text-align: center;
  }

  button {
    width: 100%;
  }
`

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await forgetPassword(email);
      alert('Link de reset enviado.')
    } catch {
      alert('Erro ao enviar link de alerta.')
    } finally {
      setLoading(false);
    }

  };

  return (
    <StyledContainer>
      <h2>Esqueci a minha senha</h2>
      <div>
        <div>
          <label htmlFor="email">E-mail:</label>
          <Input placeholder='Insira o seu e-mail' id="email" value={email} onChange={setEmail} />
        </div>
        <PrimaryButton loading={loading} text="Enviar link de reset" onClick={handleSubmit}></PrimaryButton>
      </div>
    </StyledContainer>
  );
};

export default ForgotPassword;
