import styled from "styled-components"
import Spinner from "../Spinner";

interface ButtonProps {
  text: string;
  onClick: () => void;
  loading?: boolean;
}

const StyledButton = styled.button`
  padding: 1.0rem;
  font-size: 1.6rem;
  font-weight: 700;
  color: ${props => props.theme.colors.background};
  background-color: ${props => props.theme.colors.primary};
  border: 1px solid ${props => props.theme.colors.background};
  border-radius: 0.5rem;
  cursor: pointer;
`

const StyledLoading = styled.div`
  padding: 1.0rem;
  background-color: ${props => props.theme.colors.primary};
  border: 1px solid ${props => props.theme.colors.background};
  border-radius: 0.5rem;
`;

const PrimaryButton: React.FC<ButtonProps> = ({ text, onClick, loading }) => {
  if (loading) {
    return (
      <StyledLoading>
        <Spinner />
      </StyledLoading>
    )
  }
  return (
    <StyledButton onClick={onClick}>
      {text}
    </StyledButton>
  )
}

export default PrimaryButton