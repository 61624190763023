import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import DashboardPage from './templated/Dashboard';
import LoginPage from './templated/Login';
import ForgotPasswordPage from './templated/ForgotPassword';
import ResetPasswordPage from './templated/ResetPassword';
import { isTokenExpired } from './utils/JwtUtils';


const AppRouter: React.FC = () => {

  const isNotAuthenticated = () => {
    const token = localStorage.getItem('token');
    return !token || isTokenExpired(token);
  }

  return (
    <Router>
      <Routes>
        <Route path="/home" element={<DashboardPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/forgot" element={<ForgotPasswordPage />} />
        <Route path="/reset" element={<ResetPasswordPage />} />
        <Route path="/" element={isNotAuthenticated() ? <Navigate to="/login" /> : <Navigate to="/home" />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;