import styled, { css } from "styled-components";

interface Props {
  id: string;
  value: string;
  onChange: (val: string) => void;
  label?: string;
  placeholder?: string;
  textarea?: boolean;
  password?: boolean;
  lines?: number;
}

const StyledContainer = styled.div`
  margin: 0;
`;

const baseStyles = css`
  width: 100%;
  padding: 1.0rem;
  margin: 1.0rem 0;
  box-sizing: border-box;
  border-radius: 0.5rem;
  font-size: 1.6rem;
  border: 2px solid ${props => props.theme.colors.backgroundFade2};
  background-color: ${props => props.theme.colors.backgroundFade2};
  color: ${props => props.theme.colors.main};
  &:hover {
    background-color: ${props => props.theme.colors.backgroundFade};
    border: 2px solid ${props => props.theme.colors.backgroundFade};
    outline: none;
  }
  &:focus {
    background-color: ${props => props.theme.colors.backgroundFade};
    border: 2px solid ${props => props.theme.colors.highlight};
    outline: none;
  }
  &::placeholder {
    color: ${props => props.theme.colors.faded}
  }
`

const StyledInput = styled.input`
  ${baseStyles}
`

const StyledTextArea = styled.textarea`
  ${baseStyles}
  resize: none;
`

const StyledLabel = styled.label`
  font-size: 1.6rem;
  font-weight: 700;
  color: ${props => props.theme.colors.light};
  display: block;
  pointer-events: none;
`;

const Input: React.FC<Props> = ({ 
  id, value, onChange, label, placeholder, textarea, password, lines 
}) => {
  return (
    <StyledContainer>
      { label && 
        <StyledLabel htmlFor={id}>
          {label}
        </StyledLabel>
      }

      {
        textarea ?
        <StyledTextArea 
          id={id}
          value={value} 
          onChange={e => onChange(e.target.value)} 
          placeholder={placeholder}
          rows={lines || 5}
        /> :
        <StyledInput
          id={id}
          type={password ? "password" : "text"}
          value={value} 
          onChange={e => onChange(e.target.value)} 
          placeholder={placeholder}
        />
      }


    </StyledContainer>
    
  )

}

export default Input;