import Dashboard from "../../pages/Dashboard"
import DefaultLayout from "../../layouts/DefaultLayout"
import LoginLayout from "../../layouts/LoginLayout"
import Login from "../../pages/Login"


const DashboardPage: React.FC = () => {
    return (
        <LoginLayout>
          <Login />
        </LoginLayout>
    )
}

export default DashboardPage